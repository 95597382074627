<template>
    <div id="aut" class="container authen-form">
       <div style="white-space: nowrap;">
           <div class="bg-light max-h iblock">
              <div class="text-center mt-5">
                  <img src="@/assets/Logo_Vertical.png" class="img-size" />
              </div>
              <div></div>
           </div>
           <div class="iblock" style="background:#ffffff;">
               <div class="container p-sm-5 max-h">
                   <div class="pb-4">
                     <div>
                         <span class="fw-bold display-6">WELCOME,</span>
                     </div>
                     <div>Sign In authenicate</div>
                   </div>
                   <form class="p-sm-4">
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">Email address</label>
                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                            <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
                        </div>
                        <div class="mb-3">
                            <label for="exampleInputPassword1" class="form-label">Password</label>
                            <input type="password" class="form-control" id="exampleInputPassword1">
                        </div>
                        <div class="mb-3 form-check">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="form-check-label" for="exampleCheck1">Check me out</label>
                        </div>
                        <div class="text-center">
                            <button type="button" class="btn btn-primary rounded-pill" @click="authenClick()">Sign In</button>
                        </div>
                    </form>
               </div>
           </div>
       </div>
    </div>
</template>
<script>
export default {
    name: "applin",
    components: {
        
    },
    methods: {
        authenClick() {
            this.$router.push("wealth-land")
        }
    }
}
</script>
<style scoped>
   .logo {
       color: #000000;
   }
   @media screen and (max-width: 800px) {
       .authen-form {
           position: fixed !important;
            margin-top: 0% !important;
            padding-top: 0% !important;
            top: 0px !important;
            left: 0px !important;
            margin-left: 0px !important;
        }
        .max-h {
            height: 270px !important;
            width: 100% !important;
        }
        .iblock {
            display: block !important;
        }
   }
   .authen-form {
     position: absolute;
     top:50%;
     left: 50%;
     margin-left: -400px;
     margin-top: -250px;
   }
   .iblock {
       display:inline-block;
       vertical-align:top;
   }
   .max-h {
       height: 500px;
       width: 400px;
   }
   .img-size {
       height: 180px;
   }
</style>
